// Initialize WOW Animations
new WOW().init();

$(document).ready(function(){
  var owl = $('.owl-carousel');
  var ongoingEventsOwl = $('.ongoingEventsOwl');
  var upcomingEventsOwl = $('.upcomingEventsOwl');
  var pastEventsOwl = $('.pastEventsOwl');
  var categoryOwl = $('.categoryOwl');
  var articlesOwl = $('.articlesOwl');
  var ourSelectionOwl = $('.articlesOwl');  

  owl.owlCarousel({
    nav: false,
    dots: false,
    margin: 10,
    loop: true,
    autoWidth: true,
    responsive: {
      0: {
        items: 2
      },
      640: {
        items: 2
      },
      768: {
        items: 4
      },
      1280: {
        items: 4
      }
    }
  }).trigger('refresh.owl.carousel');

  $('.previousItemOngoingEventsCarousel').click(function() {
    ongoingEventsOwl.trigger('prev.owl.carousel', [300]);
  });

  $('.nextItemOngoingEventsCarousel').click(function() {
    ongoingEventsOwl.trigger('next.owl.carousel', [300]);
  });

  $('.previousItemUpcomingEventsCarousel').click(function() {
    upcomingEventsOwl.trigger('prev.owl.carousel', [300]);
  });

  $('.nextItemUpcomingEventsCarousel').click(function() {
    upcomingEventsOwl.trigger('next.owl.carousel', [300]);
  });

  $('.previousItemPastEventsCarousel').click(function() {
    pastEventsOwl.trigger('prev.owl.carousel', [300]);
  });

  $('.nextItemPastEventsCarousel').click(function() {
    pastEventsOwl.trigger('next.owl.carousel', [300]);
  });

  $('.previousItemCategoryCarousel').click(function() {
    categoryOwl.trigger('prev.owl.carousel', [300]);
  });

  $('.nextItemCategoryCarousel').click(function() {
    categoryOwl.trigger('next.owl.carousel', [300]);
  });
  $('.previousItemArticlesCarousel').click(function() {
    articlesOwl.trigger('prev.owl.carousel', [300]);
  });

  $('.nextItemArticlesCarousel').click(function() {
    articlesOwl.trigger('next.owl.carousel', [300]);
  });

  $('.previousItemOurSelectionCarousel').click(function() {
    ourSelectionOwl.trigger('prev.owl.carousel', [300]);
  });

  $('.nextItemOurSelectionCarousel').click(function() {
    ourSelectionOwl.trigger('next.owl.carousel', [300]);
  });


});

const player = new Plyr('#player', {
    title: "SamaPass - Bien plus qu'une billetterie!",
    controls: ['play-large', 'play', 'mute', 'volume', 'pip', 'airplay', 'fullscreen']
});

